.cover {
    height: 100vh;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;

    > .container {
        position: relative;
        z-index: 10;
    }

    .display-tc {
        opacity: 0;
    }
}

.banner {
    &_top {
        background-image: url('../images/header-bg.jpg');
    }

    &_bottom {
        padding: 10em 0 3em;
        background-image: url('../images/banner-bottom.jpg');

        h2 {
            margin: 0;
            font-family: $heading-font;
            font-size: 60px;
            color: $white;
        }

        a {
            color: $white;
        }

        .heading {
            margin-bottom: 0;
        }

        .copyright {
            margin-top: 7em;
        }
    }

    &-text {
        color: $white;

        &_names {
            font-family: $heading-font;
            font-size: 40px;
            line-height: 43px;
            position: relative;

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 2px;
                height: 100%;
                top: 0;
                background-color: $white;
            }

            &::before {
                left: -1px;
            }

            &::after {
                right: -1px;
            }
        }

        span {
            padding: 0 15px;
        }
    }

    h1,
    h2 {
        margin-bottom: 20px;
        color: $white;
        font-family: $heading-font;
        font-size: 60px;
    }

    p {
        color: $white;
        margin-bottom: 0;
    }
}
