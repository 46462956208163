body {
    font-family: $font-primary;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.7;
    color: $black;
    background: $white;

    &.offcanvas {
        overflow: hidden;
    }
}

.loader {
    color: $black;
    position: fixed;
    box-sizing: border-box;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: 1010;
    background-color: $white;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    &::after {
        content: '';
        position: fixed;
        width: 48px;
        height: 48px;
        border: solid 8px $primary;
        border-left-color: transparent;
        border-radius: 50%;
        top: calc(50% - 24px);
        left: calc(50% - 24px);
        animation: rotate 1s linear infinite;
    }
}

.section {
    padding: 3em 0;
    clear: both;

    @include media-breakpoint-up(md) {
        padding: 7em 0;
    }

    &-sm {
        padding: 3.5em 0;
    }

    &-gray {
        background: $brand-lighter;
    }
}

.bg {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center center;
    width: 100%;
}

.heading {
    margin-bottom: 4em;

    &.heading-sm {
        margin-bottom: 2em;
    }

    h2 {
        margin-bottom: 10px;
        line-height: 1.5;
        font-family: $heading-font;
        font-size: 50px;
        color: $primary;
    }

    p {
        font-size: 18px;
        line-height: 1.5;

        @include media-breakpoint-up(md) {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    span {
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 2px;
        font-weight: 600;
        color: rgba($black, 0.4);
    }
}

.display-t {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100vh;
    padding-bottom: 80px;
}

.display-tc {
    h1,
    h2 {
        margin: 0;
        padding: 0;
        color: rgba($white, 1);
    }

    h1 {
        margin-bottom: 20px;
        line-height: 1.3;
        font-family: $font-heading-banner;
        font-weight: 700;
        font-size: 50px;

        @include media-breakpoint-up(md) {
            font-size: 150px;
        }
    }

    h2 {
        font-size: 16px;
        line-height: 1.5;
        margin: 30px;
        font-family: $font-primary;

        @include media-breakpoint-up(md) {
            font-size: 20px;
        }
    }

    .btn {
        padding: 15px 20px;
        background: $white !important;
        color: $primary;
        border: none !important;
        font-size: 14px;
        text-transform: uppercase;

        &:hover {
            background: $white !important;
            box-shadow: 0 14px 30px -15px rgba(0, 0, 0, 0.75) !important;
        }
    }
}

.animate-box {
    .js & {
        opacity: 0;
    }
}

img {
    max-width: 100%;
}

.social-icons {
    margin-top: 10px;
    padding: 0;

    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        color: $white;
        padding-left: 10px;
        padding-right: 10px;

        i {
            font-size: 20px;
        }
    }
}
