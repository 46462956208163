/* Countdown */
.countdown {
    &.section {
        background-image: url(../images/countdown-bg.jpg);
    }

    .heading {
        margin-bottom: 8em;

        h2 {
            font-size: 33px;

            @include media-breakpoint-up(md) {
                font-size: 50px;
            }
        }

        h2,
        p {
            color: $white;
        }
    }
}

.timer {
    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .timer-item_digits {
        display: flex;
        justify-content: center;
    }

    .timer-item_text {
        color: $white;
        font-family: $heading-font;
        font-size: 34px;
    }

    .timer-item {
        /* coutndown blocks */
        position: relative;

        > div {
            /* countdown block inner div */
            display: table-cell;
            vertical-align: middle;
            height: 100px;
            width: 100px;
        }

        .smalltext,
        .digit {
            display: block;
            color: $white;
        }

        .digit {
            font-size: 47px;
            line-height: 40px;
        }

        .smalltext {
            color: rgba($white, 0.7);
            text-transform: uppercase;
            font-size: 14px;
        }
    }
}
