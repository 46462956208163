$primary: #000;
$black: #000;
$brand-lighter: #e9e9e9;
$brand-gray: #ccc;
$brand-gray2: #8f989f;
$brand-gray3: #999999;
$brand-dark2: #333;

$h1-font-size: 60px;
$h3-font-size: 30px;

$font-primary: 'Open Sans', sans-serif;
$heading-font: 'Great Vibes', cursive;
$font-heading-banner: 'Amatic SC', cursive;

$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;
$input-border-color: $black;
$input-padding-y: 12px;
$input-padding-x: 12px;

$btn-padding-y: 12px;
$btn-padding-x: 12px;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;
$btn-padding-y-lg: 14px;
$btn-padding-x-lg: 42px;

$navbar-brand-font-size: 1.75rem;
$navbar-nav-link-padding-x: 1rem;
$navbar-nav-link-padding-y: 1rem;

$dropdown-font-size: 14px;
$dropdown-padding-x: 0.5rem;
$dropdown-padding-y: 0.5rem;
$dropdown-link-hover-bg: inherit;
$dropdown-link-color: $brand-gray3;
